import { AfterContentInit, ChangeDetectorRef, Component, OnInit, Optional, input, output } from '@angular/core';
import { Router } from '@angular/router';
import { toPromise } from '@app/_helpers/promise';
import { AppService } from '@app/app.service';
import { ProjectPageService } from '@app/pages/settings/projects/project-page/project-page.service';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { differenceInSeconds } from 'date-fns/esm';
import { combineLatest, firstValueFrom, timer } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import {
  Logger,
  MyTimesQuery,
  MyTimesService,
  Project,
  ProjectsQuery,
  ProjectsService,
  Time,
  UserService,
  UserSettingsQuery,
} from 'timeghost-api';
const log = new Logger('RecordWidgetComponent');
@UntilDestroy()
@Component({
  selector: 'tg-record-widget',
  templateUrl: './record-widget.component.html',
  styleUrls: ['./record-widget.component.scss'],
})
export class RecordWidgetComponent implements OnInit, AfterContentInit {
  readonly time$ = this.recordService.runningTime.asObservable();

  readonly switchEnabled = input(null, { alias: 'switch', transform: (v) => v === false || true });
  readonly onSwitch = output();
  readonly timeRunning$ = combineLatest([this.time$, timer(0, 1000).pipe(untilDestroyed(this))]).pipe(
    untilDestroyed(this),
    distinctUntilChanged(([prevTime], [time, x]) => (!!time || (prevTime && !prevTime.end) ? false : true)),
    map(([time]) => (time ? { ...time } : time)),
    map((time: Time & { now: Date }) => {
      if (time) time.now = new Date();
      return time;
    }),
  );
  readonly stopDisabled$ = this.timeRunning$.pipe(
    map((x) => {
      if (!x) return false;
      const start = new Date(x.start);
      const now = new Date();
      return differenceInSeconds(now, start) < 60;
    }),
  );
  constructor(
    private myTimeQuery: MyTimesQuery,
    private router: Router,
    @Optional()
    private projectPage: ProjectPageService,
    private recordService: RecordToolbarService,
    private projectService: ProjectsService,
    private projectsQuery: ProjectsQuery,
    private user: UserSettingsQuery,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private appService: AppService,
    private myTimesService: MyTimesService,
  ) {}
  private _isLoading = false;
  get isLoading() {
    return this._isLoading || this.recordService.isLoading;
  }
  set isLoading(val: boolean) {
    this._isLoading = val;
  }
  hasTimeRunning = false;
  ngOnInit(): void {
    this.time$.pipe(untilDestroyed(this)).subscribe((x) => (this.hasTimeRunning = !!x));
  }
  ngAfterContentInit() {}
  get now() {
    return new Date();
  }
  get canAdd() {
    const project = this.projectPage?.project;
    if (!project) return true;
    return !project.completed && project.projectType !== 'template';
  }
  readonly showWidget = this.user.select().pipe(
    map((user) => {
      return !(user.workspace?.settings?.requireProject || user.workspace?.settings?.requireTask);
    }),
  );
  readonly comegoOnly$ = this.appService.comegoOnly$;
  async openCreateDialog() {
    let project: Project,
      task = this.projectPage?.selectedTask.value;
    if (
      this.projectPage?.projectId &&
      (project =
        this.projectsQuery.getEntity(this.projectPage.projectId) ??
        (await this.projectService.getById(this.projectPage.projectId).catch(() => null)))
    )
      return this.recordService.openCreateDialog({ project, task });

    const value = this.recordService.group.value;
    return this.recordService.openCreateDialog({
      ...value,
    });
  }
  openUpdateDialog(time: Time, forceUpdate: boolean = false) {
    return this.recordService.openUpdateDialog(time, forceUpdate);
  }
  async startRecord() {
    this.isLoading = true;
    let project: Project,
      task = this.projectPage?.selectedTask.value;
    if ((this.user.getValue().settings?.tableDisplay as any) === 'ComeGo')
      await toPromise(
        this.userService.changeSettings({
          tableDisplay: 'Simple',
        }),
      );
    if (
      this.projectPage?.projectId &&
      (project =
        this.projectsQuery.getEntity(this.projectPage.projectId) ??
        (await this.projectService.getById(this.projectPage.projectId).catch(() => null)))
    )
      return await this.recordService.startRecord({ project, task }).finally(() => (this.isLoading = false));
    const { name, project: rproject, task: rtask } = this.recordService.group.value;
    return await this.recordService
      .startRecord({ project: rproject, task: rtask, name })
      .finally(() => this.resetLoading());
  }
  async stopRecord() {
    this.isLoading = true;
    return await this.recordService
      .stopRecord({ requireUpdate: true })
      .finally(() => this.resetLoading())
      .then((x) => {
        if (x?.length) this.recordService.resetEntites();
      });
  }
  async delete(id: string) {
    this.isLoading = true;
    return await firstValueFrom(this.myTimesService.delete({ id } as any)).finally(() => {
      this.resetLoading();
    });
  }
  resetLoading() {
    this.isLoading = false;
  }
}

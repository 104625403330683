import { Routes, Route } from '@angular/router';

import { ShellComponent } from './shell.component';
import { AppSubscriptionGuard } from '@app/app-subscription.guard';

/**
 * Provides helper methods to create routes.
 */
export class Shell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return {Route} The new route using shell as the base.
   */
  static authedChildRoutes(routes: Routes): Route {
    return {
      path: '',
      component: ShellComponent,
      children: routes,
      canActivate: [AppSubscriptionGuard],
      // Reuse ShellComponent instance when navigating between child views
      data: { reuse: true, state: 'authedShell' },
    };
  }
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return {Route} The new route using shell as the base.
   */
  static childRoutes(routes: Routes, parentRouteInfo?: Partial<Route>): Route {
    return {
      path: '',
      component: ShellComponent,
      children: routes,
      canActivate: [],
      // Reuse ShellComponent instance when navigating between child views
      data: { reuse: true, state: 'shell' },
      ...parentRouteInfo,
    };
  }

  constructor() {}
}

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CustomDirectivesModule } from '@app/_directives/custom-directives.module';
import { CreateTaskDialogModule } from '@app/components/create-task-dialog/create-task-dialog.module';
import { MaterialModule } from '@app/material.module';
import { PlainStepperModule } from '@app/shared/plain-stepper/plain-stepper.module';
import { ProjectListModule } from '@app/shared/time-tracker-calendar-stepper-create-dialog/project-list/project-list.module';
import { TaskListModule } from '@app/shared/time-tracker-calendar-stepper-create-dialog/task-list/task-list.module';
import { TranslateModule } from '@ngx-translate/core';
import { ClientProjectPickerStepperDialogComponent } from './client-project-picker-stepper-dialog.component';
import { ClientProjectPickerStepperComponent } from './client-project-picker-stepper.component';

@NgModule({
  declarations: [ClientProjectPickerStepperComponent, ClientProjectPickerStepperDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CustomDirectivesModule,
    TranslateModule,
    RouterModule,
    CdkStepperModule,
    ScrollingModule,
    CreateTaskDialogModule,
    ProjectListModule,
    TaskListModule,
    PlainStepperModule,
  ],
  exports: [ClientProjectPickerStepperDialogComponent],
})
export class ClientProjectPickerStepperDialogModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';

import { HttpClientModule } from '@angular/common/http';
import { BlockedInputDirective } from '../_directives/blocked-input.directive';

@NgModule({
  imports: [MaterialModule, CommonModule, HttpClientModule],
  declarations: [BlockedInputDirective],
  providers: [],
  exports: [],
})
export class SharedModule {}

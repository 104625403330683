import { Injectable } from '@angular/core';
import { NgxCurrencyConfig as CurrencyMaskConfig, NgxCurrencyInputMode as CurrencyMaskInputMode } from 'ngx-currency';
export const DEFAULT_CURRENCY_CONFIG: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  nullable: true,
  precision: 2,
  prefix: '$ ',
  suffix: '',
  thousands: '.',
  inputMode: 0,
  max: Number.MAX_SAFE_INTEGER,
  min: Number.MIN_SAFE_INTEGER,
};
@Injectable()
export class CurrencyConfig implements CurrencyMaskConfig {
  align: string;
  allowNegative: boolean;
  allowZero: boolean;
  decimal: string;
  precision: number;
  prefix: string;
  suffix: string;
  thousands: string;
  nullable: boolean;
  min?: number;
  max?: number;
  inputMode?: CurrencyMaskInputMode;
  constructor() {
    this.updateConfig(DEFAULT_CURRENCY_CONFIG);
  }

  updateConfig(config: Partial<CurrencyMaskConfig>) {
    return Object.assign(this, DEFAULT_CURRENCY_CONFIG, config);
  }
}

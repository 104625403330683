import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, input, output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { pushError } from '@app/_helpers/globalErrorHandler';
import { isWorkingHoursError } from '@app/_helpers/is-error-object';
import { hasPermissionByKey } from '@app/_helpers/permission';
import { createRxValue, fromRxValue } from '@app/_helpers/utils';
import { AppService } from '@app/app.service';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isBefore, roundToNearestMinutes } from 'date-fns/esm';
import produce from 'immer';
import { combineLatest, distinctUntilChanged, map, timer } from 'rxjs';
import { ComegoQuery, ComegoService, ComegoTime, ComegoTimeType, Logger, UserSettingsQuery } from 'timeghost-api';
import { ComeAndGoCreateDialogComponent } from '../come-and-go-create-dialog/come-and-go-create-dialog.component';
import { COMEGO_ICON_MAP } from '../come-and-go-create-dialog/come-and-go-utils';
import {
  ComeAndGoUpdateDialogComponent,
  ComeGoUpdateDialogData,
} from '../come-and-go-update-dialog/come-and-go-update-dialog.component';
const log = new Logger('RecordComegoWidgetComponent');
@UntilDestroy()
@Component({
  selector: 'tg-record-comego-widget',
  templateUrl: './record-comego-widget.component.html',
  styleUrls: ['./record-comego-widget.component.scss'],
})
export class RecordComegoWidgetComponent implements OnInit {
  readonly iconMap = COMEGO_ICON_MAP;
  readonly switchEnabled = input(null, { alias: 'switch', transform: (v) => v === false || true });
  readonly onSwitch = output();
  readonly loading$ = createRxValue(false);
  readonly comegoRunning$ = fromRxValue(
    combineLatest([
      this.userSettingsQuery.select(),
      this.comegoQuery.selectAll({
        filterBy: (x) => !x.end,
      }),
    ]).pipe(map(([user, times]) => times.find((x) => x.user?.id === user.id))),
  );
  readonly running$ = combineLatest([this.comegoRunning$.asObservable(), timer(0, 1000)]).pipe(
    untilDestroyed(this),
    distinctUntilChanged(([prevTime], [time, x]) => (!!time || (prevTime && !prevTime.end) ? false : true)),
    map(([time]) => (time ? { ...time } : time)),
    map((time: ComegoTime & { now: Date }) => {
      if (time) time.now = new Date();
      return time;
    }),
  );
  constructor(
    private appService: AppService,
    private comegoQuery: ComegoQuery,
    private comegoService: ComegoService,
    private userSettingsQuery: UserSettingsQuery,
    private recordService: RecordToolbarService,
    private dialog: MatDialog,
  ) {}
  readonly comegoEnabled$ = this.appService.comegoEnabled$;
  readonly canAdd$ = fromRxValue(
    this.userSettingsQuery.select().pipe(map((x) => hasPermissionByKey(x, 'groupsCanComegoCreateTimes' as any))),
    false,
  );
  ngOnInit(): void {}

  async openCreateDialog() {
    return await this.dialog.open(ComeAndGoCreateDialogComponent).afterClosed().toPromise();
  }
  openUpdateDialog(time: ComegoTime) {
    return this.dialog.open(ComeAndGoUpdateDialogComponent, {
      data: <ComeGoUpdateDialogData>{
        entity: time,
        isPastTime: false,
      },
    });
  }

  async startRecord(type?: ComegoTimeType) {
    this.loading$.next(true);
    const cuser = this.userSettingsQuery.getValue();
    const user = cuser.workspace.users.find((x) => x.id === cuser.id);
    const start = roundToNearestMinutes(new Date(), { nearestTo: 1 });
    const times = await this.comegoService
      .add({
        name: null,
        start,
        end: null,
        type: type ?? 'work',
        user,
      } as any)
      .then(this.recordService.handleWorkingHourSuccess.bind(this.recordService))
      .catch(async (err: HttpErrorResponse) => {
        pushError(err);
        if (isWorkingHoursError(err.error))
          await this.recordService.handleWorkingHourError.bind(this.recordService)(err);
        return null;
      });
    log.debug(times);
    this.resetLoading();
    return times?.[0];
  }
  async stopRecord(edit?: boolean) {
    this.loading$.next(true);
    const time = this.comegoRunning$.value;
    if (!time) return;
    let end = roundToNearestMinutes(new Date(), { nearestTo: 1 });
    if (isBefore(end, new Date(time.start))) end = new Date(time.start);
    let updatedTime: ComegoTime[] = await this.comegoService
      .update(
        produce(time, (draft) => {
          draft.end = end < new Date(draft.start) ? new Date(draft.start).toISOString() : end.toISOString();
        }),
      )
      .then(this.recordService.handleWorkingHourSuccess.bind(this.recordService))
      .catch(async (err: HttpErrorResponse) => {
        pushError(err);
        if (err.error?.message === 'errors.times.comego.overlap') {
          const prevTime =
            err.error?.refComegoId &&
            (await this.comegoService.adapter
              .get(environment.serverUrl + `/get/comego?$filter=id eq '${err.error.refComegoId}'`)
              .toPromise()
              .then((x) => x?.[0]));
          const dialogRef = this.dialog.open(ComeAndGoUpdateDialogComponent, {
            data: {
              entity: prevTime ?? time,
              isPastTime: !!prevTime,
            } as ComeGoUpdateDialogData,
            disableClose: true,
          });
          await dialogRef.afterClosed().toPromise();
        }
        if (isWorkingHoursError(err.error))
          await this.recordService.handleWorkingHourError
            .bind(this.recordService, err, [time])()
            .catch((err: any) => {
              log.error(err);
              this.resetLoading();
            });
        return null;
      });
    if (edit && updatedTime?.length) {
      const pastUpdatedTime = await this.openUpdateDialog(updatedTime?.[0]).afterClosed().toPromise();
      if (pastUpdatedTime) updatedTime = [pastUpdatedTime];
    }
    this.resetLoading();
    return updatedTime?.[0];
  }

  async pause(id: string) {
    this.loading$.next(true);
    if (!(await this.stopRecord())) return;
    await this.startRecord('pause');
  }
  async resume(id: string) {
    this.loading$.next(true);
    if (!(await this.stopRecord())) return;
    await this.startRecord('work');
  }
  async delete(id: string, template?: TemplateRef<any>) {
    if (template) {
      const allowDelete = await this.dialog
        .open(template, {
          data: {
            entity: this.comegoRunning$.value,
          },
        })
        .afterClosed()
        .toPromise()
        .then((x) => x === true)
        .catch(() => false);
      if (!allowDelete) return;
    }
    this.loading$.next(true);
    return await this.comegoService.delete({ id }).finally(() => {
      this.resetLoading();
    });
  }
  resetLoading() {
    this.loading$.next(false);
  }
}

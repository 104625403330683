import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { hasPermission } from '@app/_helpers/utils';
import { Observable } from 'rxjs';
import { UserSettingsQuery } from 'timeghost-api';

@Injectable({
  providedIn: 'root',
})
export class TeamActivityPageGuard {
  constructor(private userSettingsQuery: UserSettingsQuery) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.userSettingsQuery.getValue();
    return !!user.workspace.permissionSettings?.groupsCanSeeTeamActivity?.find((x) => hasPermission(x.id, user));
  }
}

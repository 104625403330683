import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MaterialSvgRegistryService } from './material-svg-registry.service';
import { RootPathInterceptor } from './material-svg-request-intercept';

@NgModule({
  declarations: [],
  providers: [MaterialSvgRegistryService, { provide: HTTP_INTERCEPTORS, useClass: RootPathInterceptor, multi: true }],
  imports: [CommonModule, HttpClientModule, MatIconModule]
})
export class MaterialSvgRegistryModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SVG_ICON_REG } from './material-svg-icons';

@Injectable({
  providedIn: 'root',
})
export class MaterialSvgRegistryService {
  constructor(
    private matIconReg: MatIconRegistry,
    private httpClient: HttpClient,
    private domSan: DomSanitizer,
  ) {}
  initialize() {
    this.httpClient.disableApiPrefix();
    Object.entries(SVG_ICON_REG).map(([key, x]) => {
      this.matIconReg.addSvgIcon(
        key,
        this.domSan.bypassSecurityTrustResourceUrl(window.location.origin + x.replace(/@\//, '/')),
      );
    });
  }
}

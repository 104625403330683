import { Component, Input, OnInit } from '@angular/core';
import { differenceInSeconds, isValid } from 'date-fns/esm';
import { BehaviorSubject, combineLatest, interval } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'tg-record-toolbar-counter',
  templateUrl: './record-toolbar-counter.component.html',
  styleUrls: ['./record-toolbar-counter.component.scss'],
})
export class RecordToolbarCounterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  private _start = new BehaviorSubject<Date>(null);
  readonly start$ = this._start.asObservable().pipe(distinctUntilChanged());
  get start() {
    return this._start.getValue();
  }
  @Input()
  set start(val: Date) {
    this._start.next(val);
  }

  timer$ = combineLatest([this.start$, interval(200).pipe(startWith(0))]).pipe(
    map(([start]) => {
      const date = new Date(start);
      if (!isValid(date)) return null;
      return differenceInSeconds(date, new Date());
    })
  );
}

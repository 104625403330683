import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ClientProjectModel } from '@app/classes/client-project-model';
import { UtilService } from '@app/_services/util.service';
import { SatPopover } from '@ncstate/sat-popover';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { Logger, Project, UserSettingsQuery, WorkspacesQuery } from 'timeghost-api';
import { CreateProjectComponent } from '../dialogs/create-project/create-project.component';

@Component({
  selector: 'app-clientproject-picker',
  templateUrl: './clientproject-picker.component.html',
  styleUrls: ['./clientproject-picker.component.scss'],
})
export class ClientprojectPickerComponent implements OnInit, OnDestroy {
  _onDestroy = new Subject<void>();
  @ViewChild(SatPopover, { static: true }) matMenu: SatPopover;

  searchinput: UntypedFormControl = new UntypedFormControl('');
  @Input()
  canToggle: boolean = false;
  //#region ngModels
  _selectedProject: Project;
  @Output()
  public SelectedProjectChange = new EventEmitter();
  @Input()
  get SelectedProject() {
    return this._selectedProject;
  }
  set SelectedProject(val: Project) {
    this._selectedProject = val;
    this.SelectedProjectChange.emit(val);
  }
  @Output()
  public OnClose = new EventEmitter<Project>();
  //#endregion

  @Input()
  showCreateProject: boolean = true;

  get refOrigin(): TemplateRef<any> {
    return this.matMenu._templateRef;
  }
  data$: Observable<ClientProjectModel[]> = this.utilService.clientProjects$;
  data$filtered = combineLatest([
    this.searchinput.valueChanges.pipe(startWith(''), debounceTime(150), distinctUntilChanged()),
    this.data$.pipe(startWith()),
    this.userSettingsQuery.select((x) => x.pinnedProjects),
  ]).pipe(
    filter(([, data]: [any, ClientProjectModel[], string[]]) => !!data),
    map(([search, _data, pinnedProjects]) => {
      const data: ClientProjectModel[] = [
        {
          id: -1,
          client: {
            id: 'internal',
            name: 'project.favourite',
          } as any,
          projects: [],
        },
      ];
      const ws = this.workspaceQuery.getEntity(this.userSettings.workspace.id);
      const fav = _data
        .reduce((l, r) => [...l, ...r.projects], [])
        .filter((x: Project) => pinnedProjects.indexOf(x.id) !== -1);
      data[0].projects.push(...fav);
      data.push(
        ..._data.map((c) => {
          c.projects = c.projects.filter(
            (p) =>
              pinnedProjects.indexOf(p.id) === -1 &&
              (p.private
                ? p.users.find((x) => !x.removed && x.id === this.userSettings.id) ||
                  p.groups.find(
                    (x) =>
                      !x.removed &&
                      !!ws.groups.find((y) => y.id === x.id).users?.find((u) => u.id === this.userSettings.id),
                  ) ||
                  this.userSettings.workspace.users.find((x) => x.admin && x.id === this.userSettings.id)
                : true),
          );
          return c;
        }),
      );

      return [search, data];
    }),
    map(([search, data]: [string, ClientProjectModel[]]) => {
      let singleClientCheck;
      if (search && search.trim().length > 0) {
        if (
          (singleClientCheck = data.filter(
            (x) => search.toLowerCase().indexOf((x.client?.name as string)?.toLowerCase()) !== -1,
          )).length === 1
        ) {
          return singleClientCheck
            .map((x) => {
              if (x.projects.length === 0) {
                return null;
              }
              return x;
            })
            .filter((x) => !!x);
        } else {
          return data
            .map((x) => {
              const projects = x.projects.filter((y) => y.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
              if (projects.length === 0) {
                return null;
              }
              return { ...x, projects };
            })
            .filter((x) => !!x);
        }
      } else {
        return data;
      }
    }),
    map((data) => data.sort((a, b) => (b.id === -1 ? -1 : 0))),
  );
  _data: ClientProjectModel[] = [];
  get data() {
    return this._data;
  }
  set data(val: ClientProjectModel[]) {
    if (val.length === this.filtered.length) {
      this.filtered = this._data = val;
    } else {
      this._data = val;
    }
  }
  _filtered: ClientProjectModel[];
  get filtered() {
    return this._filtered;
  }
  set filtered(val: ClientProjectModel[]) {
    this._filtered = val;
  }
  constructor(
    private utilService: UtilService,
    private dialogRef: MatDialog,
    private userSettingsQuery: UserSettingsQuery,
    private workspaceQuery: WorkspacesQuery,
  ) {}
  get userSettings() {
    return this.userSettingsQuery.getValue();
  }
  toggle() {
    this.matMenu.toggle();
  }
  trackFiltered(obj: ClientProjectModel) {
    return obj.id;
  }
  getProperty(displayProperty: string, object: ClientProjectModel) {
    let parts = displayProperty.split('.'),
      length = parts.length,
      i,
      property = object || this;
    for (i = 0; i < length; i++) {
      property = property[parts[i]];
    }
    return property;
  }
  get searchValue(): string {
    return this.searchinput.value;
  }
  filterBySearch(data: Project[]) {
    return this.searchinput && this.searchinput.value
      ? data.filter(
          (x) => this.filtered.length === 1 && x.client.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1,
        ).length > 0
        ? data
        : data.filter((x) => x.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1)
      : data;
  }
  selectItem(project: Project) {
    if (this.canToggle && !!this.SelectedProject) {
      if (project.id === this.SelectedProject.id && !project.useAsDefault) {
        this.SelectedProject = null;
        return;
      }
    }
    this.SelectedProject = project;
    this.matMenu.close(project);
  }
  ngOnDestroy() {
    log.debug('Destroyed');
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  ngOnInit() {
    this.matMenu.closed.subscribe(() => this.OnClose.emit(this.SelectedProject));
  }
  createProjectDialog() {
    this.dialogRef
      .open(CreateProjectComponent, {
        data: {
          search: this.searchValue,
        },
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe((x) => (this.SelectedProject = x));
  }
}

const log = new Logger(ClientprojectPickerComponent.name);

import { Logger } from 'timeghost-api';
import { environment } from './environment';
const log = new Logger('Webex');
const sdkSrc = 'https://binaries.webex.com/static-content-pipeline/webex-embedded-app/v1/webex-embedded-app-sdk.js';

export const checkWebexQuery = () => {
  const url = new URL(window.location.href);
  return url.searchParams?.get?.('in_webex') === '1' || typeof window.webexAppExtensions === 'function';
};
export const initWebex = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = sdkSrc;
    script.onload = (ev) => {
      resolve(ev), log.debug('loaded webex sdk');
    };
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  })
    .then(() => {
      window.isWebex = true;
      if (!window.Webex.Application) {
        log.warn('failed to init webex sdk');
        return null;
      }
      return new window.Webex.Application('BROWSER');
    })
    .then((app) => (app ? app.onReady().then(() => app) : null))
    .then((app: any) => {
      if (!app) return null;
      if (!app.isShared) {
        app.setShareUrl(environment.baseUrl + '/?in_webex=1', 'https://web.timeghost.io/', 'timeghost');
      }
      return app;
    })
    .catch((err) => {
      log.error(err);
      return null;
    });
};

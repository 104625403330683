import { Router } from '@angular/router';
import { resetStores } from '@datorama/akita';
import { environment } from '@env/environment';
import { isTeamsContext, isTeamsWindow } from '@env/msal';
import * as Sentry from '@sentry/angular-ivy';
import produce from 'immer';
import { debounce, merge } from 'lodash-es';
import {
  ApplicationSettingsQuery,
  ApplicationSettingsStore,
  BaseService,
  ComegoQuery,
  ComegoService,
  Logger,
  MyTimesQuery,
  MyTimesService,
  ProjectsQuery,
  ProjectsService,
  Time,
  UserService,
  UserSettings,
  UserSettingsQuery,
  WorkspacesService,
} from 'timeghost-api';
import { ProjectsStore } from 'timeghost-api/lib/stores/projects/projects.store';

import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { checkWebexQuery, initWebex } from '@env/webex';
import { TranslateService } from '@ngx-translate/core';
import { MyTimesStore } from 'timeghost-api/lib/stores/myTimes/myTimes.store';
import getSupportedLanguages from './_helpers/getSupportedLanguages';
import { initRxEncryption } from './_helpers/rx-value';
import { isNullOrUndefined, nextTickAsync, queryParams } from './_helpers/utils';
import { getBrowserTimezone } from './account-profile/custom-timezones';
import appAuth from './app-auth-handler';
import { AppService } from './app.service';
import { I18nService } from './core';
import { TeamsService } from './services/teams.service';
import { RecordToolbarService } from './shared/record-toolbar/record-toolbar.service';
import { UserAvatarService } from './shared/user-avatar/user-avatar.service';

const log = new Logger('AppLoad-Handler');
export const initializeSentry = (user: UserSettings) => {
  Sentry.setTags({
    'user.id': user.id,
    'user.name': user.officeProfile?.displayName,
    'user.workspaceId': user.workspace?.id ?? user.defaultWorkspace,
    'user.email': user.email,
    'user.defaultWorkspaceId': user.defaultWorkspace,
    'user.workspaceName': user.workspace?.name,
    'user.language': user.settings?.languageSetting || Intl.DateTimeFormat().resolvedOptions().locale,
    'user.timezone': user.settings?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    'user.release': environment.releaseName,
    ...(user.workspace
      ? {
          'user.subscription.provider': user.workspace.subscription?.provider,
          'user.subscription.quantity': user.workspace.subscription?.quantity?.toString(),
          'user.subscription.interval': user.workspace.subscription?.recurringInterval,
          'user.subscription.status': user.workspace.subscription?.provider,
        }
      : {}),
  });
};
const resetAppSettings = (appSettings: ApplicationSettingsStore) => {
  return appSettings.update((draft) => {
    draft = merge(draft || {}, {
      dashboard: {},
      project: {},
      client: {},
      reports: {},
      workspace: {
        active: null,
      },
      config: {},
      userId: null,
    });
    return draft;
  });
};
export function initialize(
  baseService: BaseService,
  i18n: I18nService,
  appService: AppService,
  teamsService: TeamsService,
  userSettingsQuery: UserSettingsQuery,
  userService: UserService,
  workspaceService: WorkspacesService,
  projectsService: ProjectsService,
  myTimesService: MyTimesService,
  myTimesQuery: MyTimesQuery,
  comegoService: ComegoService,
  comegoQuery: ComegoQuery,
  appSettings: ApplicationSettingsStore,
  appSettingsQuery: ApplicationSettingsQuery,
  projectQuery: ProjectsQuery,
  recordService: RecordToolbarService,
  router: Router,
  msal: MsalService,
  userAvatars: UserAvatarService,
  translate: TranslateService,
  http: HttpClient,
) {
  return initializeHandler({
    baseService,
    i18n,
    appService,
    teamsService,
    userSettingsQuery,
    userService,
    workspaceService,
    projectsService,
    myTimesService,
    myTimesQuery,
    comegoService,
    comegoQuery,
    appSettings,
    appSettingsQuery,
    projectQuery,
    recordService,
    router,
    msal,
    userAvatars,
    translate,
    http,
  });
}
export type InitServiceArgs = {
  baseService: BaseService;
  i18n: I18nService;
  appService: AppService;
  teamsService: TeamsService;
  userSettingsQuery: UserSettingsQuery;
  userService: UserService;
  workspaceService: WorkspacesService;
  projectsService: ProjectsService;
  myTimesService: MyTimesService;
  myTimesQuery: MyTimesQuery;
  comegoService: ComegoService;
  comegoQuery: ComegoQuery;
  appSettings: ApplicationSettingsStore;
  appSettingsQuery: ApplicationSettingsQuery;
  projectQuery: ProjectsQuery;
  recordService: RecordToolbarService;
  router: Router;
  msal: MsalService;
  userAvatars: UserAvatarService;
  translate: TranslateService;
  http: HttpClient;
};
const initializeHandler = function ({
  baseService,
  i18n,
  appService,
  userSettingsQuery,
  userService,
  workspaceService,
  projectsService,
  myTimesService,
  myTimesQuery,
  appSettings,
  projectQuery,
  recordService,
  router,
  userAvatars,
  translate,
  ...sargs
}: InitServiceArgs) {
  const isTeams = isTeamsWindow();
  const isWebex = checkWebexQuery();
  const qp = queryParams();
  log.debug('init API');
  log.debug('teams: ' + isTeams);
  log.debug('tz:', getBrowserTimezone());
  log.debug('args:', arguments);
  log.debug('query', qp);
  window.dataLayer.push({
    platform: isTeams ? 'teams' : 'web',
  });
  if (environment.production && localStorage.version !== (environment.releaseName || environment.version)) {
    resetStores();
  }
  if (qp.devEnv) window.devEnv = qp.devEnv;
  const currentUser = userSettingsQuery.getValue(),
    currentSettings = appSettings.getValue();
  if (
    (currentUser?.id && currentUser.id !== currentSettings?.userId) ||
    (currentSettings?.workspace?.active && currentUser.workspace.id !== currentSettings.workspace.active) ||
    !currentSettings ||
    Object.keys(currentSettings).length === 0
  ) {
    resetStores();
    resetAppSettings(appSettings);
  }
  return () => {
    let contextBase: any = null;
    return new Promise(async (resolve, reject) => {
      if (/^\/auth\/(\w+)/.test(window.location.pathname)) {
        await baseService.init().catch((err) => log.error(err));
        if (isTeams) {
          await sargs.teamsService
            .initialize()
            .then(() => sargs.teamsService.isInitialized() && sargs.teamsService.getInstance()?.app)
            .then(async (app) => {
              if (app) {
                appService.parentHost.update('teams');
                await sargs.teamsService.setUserData();
              }
              contextBase = app;
              await appService.loadThemeIfUnloaded().catch(() => null);
              return app;
            })
            .catch((err) => {
              log.error('teams load error', err);
            });
        }
        return resolve(null);
      }
      const isChildWindow = window.parent && window !== window.parent;
      if (isChildWindow) {
        try {
          if (window.parent.location.hostname && window.parent.location.hostname === window.location.hostname)
            window.close();
        } catch {}
      }
      await baseService
        .init()
        .then(() =>
          isTeams
            ? sargs.teamsService
                .initialize()
                .then(() => sargs.teamsService.isInitialized() && sargs.teamsService.getInstance()?.app)
                .then(async (app) => {
                  if (app) {
                    appService.parentHost.update('teams');
                    await sargs.teamsService.setUserData();
                  }
                  contextBase = app;
                  await appService.loadThemeIfUnloaded().catch(() => null);
                  return app;
                })
            : Promise.resolve(null),
        )
        .then(() =>
          (appAuth.bind(this, ...arguments)() as Promise<any>).then((res) => {
            if (res === 'teams_auth_page') resolve(null);
            return res;
          }),
        ) // handle authentication
        .then(async () => {
          if (isWebex) {
            // invoke only after MSAL initialized
            await loadWebex(appService, baseService);
          }

          return userService
            .getCurrentUser()
            .toPromise()
            .then((user) => {
              baseService.checkConsent();
              initRxEncryption(user);
              return user;
            });
        })
        .then(() => baseService.checkConsent().catch(() => null))
        .then(() => {
          return new Promise<any | void>(async (resolveWorkspace) => {
            await nextTickAsync();
            // run on next tick
            const user = userSettingsQuery.getValue(),
              urlTree = router.parseUrl(location.pathname + location.search),
              // @ts-ignore
              workspaceId = urlTree.queryParams?.workspaceid;
            if (!workspaceId) return resolveWorkspace(null);
            const ws = user.workspaces.find((x) => x.id === workspaceId);
            delete urlTree.queryParams.workspaceid;
            if (!ws || workspaceId === user.workspace.id) return resolveWorkspace(null);
            appService.resetStores();
            resetAppSettings(appSettings);
            const prevWs = { ...user.workspace };
            return userService
              .changeWorkspace({ id: workspaceId } as any)
              .then(async (userSettings) => {
                userSettingsQuery.__store__.update(userSettings);
                await appService.reinitializeStores(['workspaces', 'projects']);
                await appService.connectSignal(true);
                appService.initSignalReconnectOnFailed();

                router.navigateByUrl(urlTree);
                return userSettings;
              })
              .then(resolveWorkspace)
              .catch(() => {
                if (prevWs && user.workspace?.id !== prevWs.id)
                  return userService.changeWorkspace(prevWs).then(resolveWorkspace).catch(resolveWorkspace);
                return resolveWorkspace(null);
              });
          });
        })
        .then(async () => {
          const app = contextBase;
          const userSettings = userSettingsQuery.getValue(),
            theme = userSettings?.settings?.theme ?? 'default';
          if (app && isTeamsContext(app)) {
            await app.getContext().then(async (context) => {
              if (!context) return await appService.setMode(theme, true);
              if (appService.selectedTheme !== 'default') return;
              log.debug('teams:ctx', context);
              if (context.app.theme === 'dark') await appService.setMode(theme, true);
            });
            app.registerOnThemeChangeHandler(
              async (theme: string) =>
                appService.selectedTheme === 'default' && theme && (await appService.setMode('default', true, true)),
            );
          } else {
            if (window.matchMedia) {
              appService.setMode(theme, true);
              const browserStyleContext = window.matchMedia('(prefers-color-scheme: dark)');
              if (browserStyleContext.addEventListener)
                browserStyleContext.addEventListener(
                  'change',
                  debounce(async (e) => {
                    if (appService.selectedTheme === 'default')
                      await appService.setMode(e.matches ? localStorage.theme : 'default');
                  }, 100),
                );
            } else {
              await appService.setMode(localStorage.theme, true);
            }
          }
        })
        .then(() => {
          const userSettings = userSettingsQuery.getValue();
          appSettings.update((state) => {
            state.workspace = Object.assign({}, userSettings.workspace || {}, { active: userSettings.workspace?.id });
            state.userId = userSettings.id;
          });
          if (
            !userSettings.settings ||
            Object.keys(userSettings.settings).length === 0 ||
            Object.values(userSettings.settings).findIndex((x) => isNullOrUndefined(x)) !== -1
          ) {
            const languageSetting =
              userSettings.settings?.languageSetting ??
              {
                de: 'de-DE',
                en: 'en-US',
              }[Intl.DateTimeFormat()?.resolvedOptions()?.locale] ??
              'en-US';
            return userService
              .changeSettings({
                timeZone: userSettings.settings?.timeZone ?? getBrowserTimezone(),
                tableDisplay: userSettings.settings.tableDisplay || 'Simple',
                captureManualMode: userSettings.settings?.captureManualMode ?? true,
                feedShowBooked: userSettings.settings?.feedShowBooked ?? true,
                languageSetting,
                timeFormat24h: userSettings.settings?.timeFormat24h ?? languageSetting !== 'en-US',
              })
              .toPromise();
          } else {
            const timeZone = getBrowserTimezone();
            if (!userSettings.settings?.timeZone && timeZone) {
              return userService
                .changeSettings({
                  timeZone: timeZone,
                })
                .toPromise();
            }
          }
          return userSettings;
        })
        .then((userSettings) => {
          i18n.init(environment.defaultLanguage, getSupportedLanguages());
          i18n.language = userSettings.settings.languageSetting || environment.defaultLanguage;
          window.dataLayer.push({
            Language: i18n.language,
          });
          if (userSettings.workspace && !userSettings.workspace.settings?.timesMode) {
            return produce(userSettings, (draft) => {
              draft.workspace.settings = {
                ...draft.workspace.settings,
                timesMode: 'range',
              };
            });
          }
          return userSettings;
        })
        .then((user) => {
          initializeSentry(userSettingsQuery.getValue());
          return Promise.all([
            workspaceService.get().toPromise(),
            projectsService.get().toPromise(),
            Promise.resolve(user),
          ]);
        }) // load projects/workspaces on init (prevent null projects)
        .then(async ([workspaces, projects, user]) => {
          if (!user.pinnedProjects) {
            produce(user, (draft) => {
              draft.pinnedProjects = [];
            });
          }
          (projectQuery.__store__ as ProjectsStore).remove((x) => x.completed);
          const defaultProject = projects?.find((x) => x.useAsDefault);

          if (defaultProject)
            recordService.group.patchValue({
              project: defaultProject,
            });

          /**
           * removal of invalid/outdated recording times
           */
          const runningTimes: Time[] = await myTimesService.getLatestRecordings(1).catch(() => []);
          if (runningTimes?.length > 0)
            (myTimesQuery.__store__ as MyTimesStore)?.remove(
              (t) => runningTimes.find((rt) => rt.id === t.id) && !t.end,
            );
          else (myTimesQuery.__store__ as MyTimesStore)?.remove((t) => !t.end);
          return user;
        })
        .then(() => {
          localStorage.version = environment.releaseName || environment.version;

          window.dataLayer.push({
            event: 'PageStatus',
            data: 'InitializationCompleted',
          });
          userAvatars.initialize(); // fetch avatars async queued
          resolve(null);
        })
        .then(
          () =>
            translate
              .getTranslation(translate.currentLang)
              .toPromise()
              .then((translations) => {
                log.debug('translations', translations);
                return translations;
              })
              .catch(() => null), // load set lang
        )
        .catch((err) => {
          console.error(err);
        });
    });
  };
};

function loadWebex(appService: AppService, baseService: BaseService): Promise<void> {
  return initWebex()
    .finally(() => {
      log.debug('query webex', queryParams());
    })
    .then((app) => {
      log.debug('webex app', app);
      appService.parentHost.update('webex');
      // contextBase = app;
      return app?.context?.getUser?.()?.then(async (user: any) => {
        if (!user) return null;
        await baseService.checkConsent();
        await appService.setWebexUser(user);
        return app;
      });
    })
    .catch((err) => {
      log.error(err);
      return null;
    });
}

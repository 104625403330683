<div class="flex items-center space-x-2">
  <div
    class="flex h-12 items-center space-x-3 rounded-lg wflex-[1_0_auto]"
    [ngClass]="{ 'mat-bdw-10 dark:mat-bd-20 pl-4 pr-1': (isActive$ | async) }"
  >
    <ng-container *ngIf="{ stats: activeTime$stats | async, visit: lastVisit$ | async } as time">
      <ng-container
        *ngTemplateOutlet="
          time.stats ? statView : time.visit ? null : null;
          context: { $implicit: time.stats, visit: time.visit }
        "
      ></ng-container>
    </ng-container>
    <div class="flex items-center justify-end space-x-1 wflex-[1_0_auto]">
      <ng-container *ngTemplateOutlet="(isActive$ | async) ? activeActions : defaultActions"></ng-container>
    </div>
  </div>
  <ng-container *ngIf="workspace$canCreate | async">
    <ng-container *ngTemplateOutlet="manualAdd"></ng-container>
  </ng-container>
</div>
<ng-template #statViewInitial let-visit="visit">
  <div class="mat-typography flex flex-col pt-0.5 leading-none">
    <div class="mb-0.5 flex items-end space-x-1">
      <span class="self-start">{{ visit.visit | formatDate: 'time' }}</span>
    </div>
    <span class="mat-hint text-sm leading-none">{{ 'comego.first_app_visit' | translate }}</span>
  </div>
</ng-template>
<ng-template #statView let-stats>
  <div class="mat-typography flex flex-col pt-0.5 leading-none">
    <div class="mb-0.5 flex items-end space-x-1">
      <span class="leading-none">{{ stats.duration | parseMsAsDuration }}</span>
      <span class="mat-hint text-sm leading-none">{{ stats.start | formatDate: 'time' }}</span>
    </div>
    <span class="text-sm leading-none" [ngClass]="{ 'mat-hint': !stats.paused, 'mat-text-accent': stats.paused }">{{
      (stats.paused ? 'comego.paused_since' : 'comego.tracking_since') | translate
    }}</span>
  </div>
</ng-template>
<ng-template #pauseAction>
  <button
    mat-button
    class="mat-button-square wflex-[0_0_auto]"
    [matTooltip]="'comego.state.pause' | translate"
    (click)="handle('update', 'pause')"
    [disabled]="loading"
  >
    <mat-icon>pause_circle</mat-icon>
  </button>
</ng-template>
<ng-template #resumeAction>
  <button
    mat-button
    class="mat-button-square wflex-[0_0_auto]"
    [matTooltip]="'comego.state.resume' | translate"
    (click)="handle('update', 'play')"
    [disabled]="loading"
  >
    <mat-icon>not_started</mat-icon>
  </button>
</ng-template>
<ng-template #activeActions>
  <ng-container *ngTemplateOutlet="!(paused$ | async) ? pauseAction : resumeAction"></ng-container>
  <button
    mat-raised-button
    class="mat-comego-stop h-10 !rounded-r-lg wflex-[0_0_auto]"
    (click)="handle('stop')"
    [disabled]="loading"
  >
    <div class="flex items-center space-x-2">
      <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white/20">
        <div class="mat-size-4">
          <mat-icon inline>logout</mat-icon>
        </div>
      </div>
      <span>{{ 'comego.check_out' | translate }}</span>
    </div>
  </button>
</ng-template>
<ng-template #defaultActions>
  <button
    mat-raised-button
    class="mat-comego-start h-10 !rounded-lg wflex-[0_0_auto]"
    (click)="handle('start')"
    [disabled]="loading"
  >
    <div class="flex items-center space-x-2">
      <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white/20">
        <div class="mat-size-4">
          <mat-icon inline>login</mat-icon>
        </div>
      </div>
      <span>{{ 'comego.check_in' | translate }}</span>
    </div>
  </button>
</ng-template>
<ng-template #manualAdd>
  <button
    mat-button
    class="mat-icon-button mat-icon-button-flex h-10 w-10 !rounded-lg wflex-[0_0_auto]"
    (click)="handle('manual')"
    [disabled]="loading"
    [matTooltip]="'comego.manual-create' | translate"
  >
    <div class="flex items-center space-x-2">
      <mat-icon>add</mat-icon>
    </div>
  </button>
</ng-template>
<ng-template #btnLoading> </ng-template>

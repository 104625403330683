<sat-popover
  *ngIf="!!this.satAnchor"
  [verticalAlign]="this.satLocation"
  [interactiveClose]="true"
  class="menuWidth"
  [hasBackdrop]="this.hasBackdrop"
  [id]="this.id"
  [anchor]="this.satAnchor$ | async"
>
  <mat-card class="static-date-picker mat-elevation-z6 !p-0">
    <mat-card-content>
      <div class="flex flex-col">
        <mat-calendar
          #appDate
          [selected]="selectedDate$ | async"
          (selectedChange)="changeSelectedDate($event)"
          [startAt]="selectedDate$ | async"
          [maxDate]="this.maxDate"
        ></mat-calendar>
      </div>
    </mat-card-content>
  </mat-card>
</sat-popover>

import { InjectionToken } from '@angular/core';
import { initializeSentry } from '@app/app-load-handler';
import { akitaConfig, PersistState, persistState } from '@datorama/akita';
import { persistStateSettings, UserSettings } from 'timeghost-api';
const createPersistState = () => {
  akitaConfig({ resettable: true });
  return persistState({
    ...persistStateSettings,
    preStoreUpdate(store: any, state: any) {
      if (store === 'userSettings' && (state as UserSettings)?.id) {
        const user = state as UserSettings;
        if (user) initializeSentry(user);
      }
      return persistStateSettings.preStorageUpdate(store, state);
    },
  });
};
const _state = createPersistState();

export const akitaPersistState = _state;
export const persistStateToken = new InjectionToken<PersistState>('persistState', {
  factory: () => akitaPersistState,
});
export const AkitaPersistState = { provide: 'persistStorage', useValue: akitaPersistState, multi: false };

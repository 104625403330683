import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomPipesModule } from '@app/_pipes/custom-pipes.module';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { DateFnsModule } from 'ngx-date-fns';
import { TaskListComponent } from './task-list.component';

@NgModule({
  declarations: [TaskListComponent],
  imports: [
    CommonModule,
    MaterialModule,
    CustomPipesModule,
    FormsModule,
    ReactiveFormsModule,
    DateFnsModule,
    TranslateModule,
    ScrollingModule,
    CdkTrapFocus,
    ExperimentalScrollingModule,
  ],
  exports: [TaskListComponent],
})
export class TaskListModule {}

<div class="flex flex-col items-center justify-end gap-3" *ngIf="!(comegoOnly$ | async)">
  <div class="flex items-end justify-end space-x-2">
    @if (switchEnabled() === true) {
      <div class="mb-2 flex items-center space-x-2">
        <button
          mat-raised-button
          mat-icon-button
          color="light"
          (click)="onSwitch.emit('comego')"
          [disabled]="isLoading"
          [matTooltip]="'Swap to working hour times'"
          matTooltipPosition="left"
        >
          <mat-icon>swap_horizontal</mat-icon>
        </button>
      </div>
    }
    <div class="flex flex-col items-center justify-end gap-3">
      <button
        mat-mini-fab
        color="primary"
        (click)="openCreateDialog()"
        [disabled]="!canAdd || isLoading"
        [matTooltip]="'timer.widget.create' | translate"
      >
        <mat-icon>add</mat-icon>
      </button>
      <ng-container *ngIf="this.timeRunning$ | async as tr">
        <button
          mat-mini-fab
          class="bg-red-500 text-white"
          (click)="delete(tr.id)"
          [disabled]="isLoading"
          [matTooltip]="'time.delete' | translate"
          matTooltipPosition="left"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          this.isLoading ? loadingFab : normalFabTimer;
          context: {
            time: timeRunning$ | async,
            timeDisabled: stopDisabled$ | async,
          }
        "
      ></ng-container>
    </div>
  </div>
  <ng-template #runningFabTimer let-time="time">
    <button mat-raised-button color="accent" (click)="stopRecord()">
      <div class="line-height-base -ml-3 -mr-1 flex h-10 flex-row items-center justify-start gap-1">
        <mat-icon>stop</mat-icon>
        {{ time.start | dfnsParseIso | dfnsDifferenceInSeconds: time.now | parseMsAsDuration: true }}
      </div>
    </button>
  </ng-template>
  <ng-template #normalFabTimer let-time="time" let-timeDisabled="timeDisabled">
    <div
      matTooltip
      [matTooltipTemplate]="tooltipCounter"
      [matTooltipTemplateContext]="{ time: time, timeDisabled: timeDisabled }"
      matTooltipPosition="left"
    >
      <button
        mat-fab
        (click)="time ? stopRecord() : startRecord()"
        [disabled]="!canAdd || (stopDisabled$ | async)"
        fxHide.lt-lg
      >
        <mat-icon *ngIf="!time; else runningTime">play_arrow</mat-icon>
        <ng-template #runningTime>
          <mat-icon>stop</mat-icon>
        </ng-template>
      </button>
      <div class="relative" fxHide.gt-md>
        <button mat-fab (click)="time ? stopRecord() : startRecord()">
          <mat-icon *ngIf="!time; else runningTime">play_arrow</mat-icon>
          <ng-template #runningTime>
            <mat-icon>stop</mat-icon>
          </ng-template>
        </button>
        <div
          class="mbg-tooltip absolute bottom-0 right-full mr-2 block min-w-48 rounded px-2.5 py-2 text-left shadow"
          *ngIf="time && !time.end"
        >
          <ng-container
            *ngTemplateOutlet="tooltipCounter; context: { time: time, timeDisabled: timeDisabled }"
          ></ng-container>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #loadingFab let-time="time">
    <button mat-fab disabled>
      <mat-progress-spinner [strokeWidth]="3" [diameter]="20" color="accent" [mode]="'indeterminate'">
      </mat-progress-spinner>
    </button>
  </ng-template>
</div>
<ng-template #tooltipCounter let-time="time" let-timeDisabled="timeDisabled">
  <ng-container *ngIf="time && !time.end; else tooltipRecordAction">
    <div class="flex flex-col space-y-2.5">
      <ng-container *ngIf="timeDisabled">
        <div class="pt-0.5">{{ 'time.min-hint' | translate }}</div>
        <mat-divider inline></mat-divider>
      </ng-container>
      <div class="flex flex-row items-center justify-start gap-2">
        <mat-icon>timer</mat-icon>
        <div class="flex flex-col">
          <span class="font-bold">{{ time.name }}</span>
          <div class="flex flex-row items-center justify-start gap-1">
            <span class="op-70 text-sm font-bold" *ngIf="time.project as project">{{
              project | defaultEntityTranslate: 'project' : 'project.none'
            }}</span>
            <ng-container *ngIf="time.task?.name as taskName">
              <div class="mat-bg-30 h-1 w-1 flex-shrink-0 rounded-full"></div>
              <span class="op-70 truncate text-sm">{{ taskName | truncate: 35 }}</span>
            </ng-container>
          </div>
          <span class="mt-1">{{
            time.start | dfnsParseIso | dfnsDifferenceInSeconds: time.now | parseMsAsDuration: true
          }}</span>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #tooltipRecordAction> {{ 'timer.widget.start' | translate }} </ng-template>
</ng-template>

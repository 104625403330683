import { Injectable, ElementRef, ComponentRef } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DatepickerMenuComponent } from './datepicker-menu.component';
import { Logger } from 'timeghost-api';

const DEFAULT_CONFIG: DatepickerMenuConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tg-datepicker-dialog-panel'
};

const log = new Logger('DatepickerMenuService');

@Injectable({
  providedIn: 'root'
})
export class DatepickerMenuService {
  // Inject overlay service
  constructor(private overlay: Overlay) {}

  private getOverlayConfig(ref: ElementRef, config: DatepickerMenuConfig): OverlayConfig {
    const positionStrategy = this.overlay.position().flexibleConnectedTo(ref);

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy
    });

    return overlayConfig;
  }
  private createOverlay(ref: ElementRef, config: DatepickerMenuConfig) {
    // Returns an OverlayConfig
    const overlayConfig = this.getOverlayConfig(ref, config);

    // Returns an OverlayRef
    return this.overlay.create(overlayConfig);
  }
  open(ref: ElementRef, config: DatepickerMenuConfig = {}) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };
    // Returns an OverlayRef (which is a PortalHost)
    const overlayRef = this.createOverlay(ref, dialogConfig);

    // Create ComponentPortal that can be attached to a PortalHost
    const datepickerPortal = new ComponentPortal(DatepickerMenuComponent);
    log.debug('open', datepickerPortal, dialogConfig, overlayRef);

    // Attach ComponentPortal to PortalHost
    return overlayRef.attach(datepickerPortal);
  }
  close(ref: ElementRef, componentRef: ComponentRef<DatepickerMenuComponent>) {
    componentRef.destroy();
  }
}

export interface DatepickerMenuConfig {
  hasBackdrop?: boolean;
  backdropClass?: string | string[];
  panelClass?: string | string[];
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { DateFnsModule } from 'ngx-date-fns';
import { RecordComegoWidgetComponent } from './record-comego-widget.component';

@NgModule({
  declarations: [RecordComegoWidgetComponent],
  imports: [CommonModule, MaterialModule, TranslateModule, FormsModule, ReactiveFormsModule, DateFnsModule],
  exports: [RecordComegoWidgetComponent],
})
export class RecordComegoWidgetModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatepickerMenuComponent } from './datepicker-menu.component';
import { DatepickerMenuService } from './datepicker-menu.service';
import { MaterialModule } from '@app/material.module';
import { DatepickerMenuDirective } from './datepicker-menu.directive';

@NgModule({
  declarations: [DatepickerMenuDirective, DatepickerMenuComponent],
  imports: [CommonModule, MaterialModule],
  providers: [DatepickerMenuService],
})
export class DatepickerMenuModule {}

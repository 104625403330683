import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DurationInputControlModule } from '@app/components/duration-input-control/duration-input-control.module';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxMaskDirective } from 'ngx-mask';
import { ProjectTemplateDialogModule } from '../project-template-dialog/project-template-dialog.module';
import { ClientPickerDialogModule } from './../client-picker-dialog/client-picker-dialog.module';
import { CreateProjectComponent } from './create-project.component';

@NgModule({
  declarations: [CreateProjectComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,

    ClientPickerDialogModule,
    TranslateModule,
    ColorPickerModule,
    DurationInputControlModule,
    NgxMaskDirective,
    NgxCurrencyDirective,
    ProjectTemplateDialogModule,
  ],
  exports: [CreateProjectComponent],
})
export class CreateProjectModule {}
